//
import React from "react"

//
import Layout from '../layout/index';
import Home from 'routes/home/index.js';

/*
 *
 */
export default (props) => (
	<Layout {...props}>
		<Home />
	</Layout>
);